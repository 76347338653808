<template>
	<div>
		<a-config-provider :locale="zh_CN">
			<a-card>
				<a-row style="margin-bottom: 20px;">
					<a-col :span="24">
						<a-range-picker @change="DiscoverytimeChange" :placeholder="[l('WEStartDate'), l('WEEndDate')]" v-model="nowTime"
						 style=" width: 250px;text-align: center;margin-right:10px;" />
					</a-col>
				</a-row>
				<a-spin :spinning="isLoading">
					<a-table :columns="columns" :data-source="tableData" :customRow="customRow"
					    :rowKey="(record)=>record.id" :scroll="{x:scroll_x,y:600}" :pagination="false"
					    size="small">
						
					</a-table>
				</a-spin>
				<a-pagination class="pagination" :total="totalItems" showSizeChanger showQuickJumper
					:defaultPageSize="request.maxResultCount" :pageSizeOptions="pageSizeOptions"
					:show-total="total => `共有 ${totalItems} 条`" @change="onChangePage"
					@showSizeChange="showSizeChange" />
			</a-card>
		</a-config-provider>
	</div>
</template>
<script>
	import {
		AppComponentBase
	} from "@/shared/component-base";
	import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
	import {
		ReportServiceProxy,LayoutOrgServiceProxy
	} from '../../../shared/service-proxies';
	import moment from "moment";
	import * as echarts from 'echarts';

	let _this;
	export default {
		name: "NoClose-issue",
		mixins: [AppComponentBase],
		components: {

		},
		created() {
			_this = this;
			this.ReportServiceProxy = new ReportServiceProxy(this.$apiUrl, this.$api);
			this._LayoutOrgServiceProxy = new LayoutOrgServiceProxy(this.$apiUrl, this.$api);
		},
		mounted() {
			this.function();
			this.getData();
		},
		data() {
			return {
				zh_CN,
				isLoading: true, //加载中
				startTime: undefined,
				endTime: undefined,
				nowTime: [],
				
				totalItems: 0, //总数
				// 当前页码
				pageNumber: 1,
				// 共多少页
				totalPages: 1,
				// 条数显示范围
				pagerange: [1, 1],
				// 显示条数
				pageSizeOptions: ["10", "20", "50", "100", "500"],
				request: {
					maxResultCount: 20,
					skipCount: 0
				},
				
				tableData: [],
				columns: [
				{
				    title: this.l('issue.description'),
				    width: 250,
				    dataIndex: 'description',
				    align: 'left',
					ellipsis: true,
				},
				{
				    title: this.l('Responseible'),
				    width: 100,
				    dataIndex: 'respName',
				    align: 'center',
				},
				{
				    title: this.l('issue.history.discoveryTime'),
				    width: 150,
				    dataIndex: 'discoveryTime',
				    align: 'center',
				},
				{
				    title: this.l('auditpoint'),
				    width: 300,
				    dataIndex: 'auditPoint',
				    align: 'center',
				},
				{
				    title: this.l('AuditItem'),
				    width: 500,
				    dataIndex: 'auditItemName',
				    align: 'left',
					ellipsis: true,
				}
				],
			}
		},
		computed: {
			
		},
		methods: {
			moment,
			function() {
				// this.yearDate = moment(new Date());
				let startTime = new Date().getFullYear() + '-01-01';
				let endTime = new Date().getFullYear() + '-12-31';
				this.nowTime = [moment(startTime), moment(endTime)];
				this.startTime = moment(startTime);
				this.endTime = moment(endTime);
			},
			getData() {
				this.isLoading = true;
				this.ReportServiceProxy.getIssueThirtyDayNoCloseForList(
					this.startTime,
					this.endTime,
					undefined,
					undefined,
					this.request.maxResultCount,
					this.request.skipCount,
				).finally(() => {
					this.isLoading = false;
				}).then(res => {
					this.tableData = res.items;
					this.tableData.map(item => {
						item.discoveryTime = item.discoveryTime ? moment(item.discoveryTime).format(
							'YYYY-MM-DD HH:mm') : "";
					})
					
					this.totalItems = res.totalCount;
					this.totalPages = Math.ceil(
						res.totalCount / this.request.maxResultCount
					);
					this.pagerange = [
						(this.pageNumber - 1) * this.request.maxResultCount + 1,
						this.pageNumber * this.request.maxResultCount,
					];
				})
			},
			
			//选择日期
			DiscoverytimeChange(date, dateString) {
				this.startTime = date[0];
				this.endTime = date[1];
				this.getData();
			},
			
			//分页
			onChangePage(page, pageSize) {
				this.pageNumber = page;
				this.request.skipCount = (page - 1) * this.request.maxResultCount;
				this.getData();
			},
			
			showSizeChange(current, size) {
				this.pageNumber = 1;
				this.request.maxResultCount = size;
				this.request.skipCount = (this.pageNumber) * this.request.maxResultCount - size;
				this.getData();
			},
		}
	}
</script>

<style>
</style>
